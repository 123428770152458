/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
        <vx-card class="mb-base" v-if="currentVM === 1">
          <div class="flex items-center justify-between w-full">
            <h3 class="font-bold mb-4">VM Configurator</h3>
            <h3 class="font-light mb-4">{{ currentVM }}/2</h3>
          </div>

          <div class="py-4">
            <span class="flex items-center gap-x-2 mb-2 text-dark">
              <font-awesome-icon icon="fa-solid fa-server" class="h-5 w-5" />
              <h4>Hypervisor</h4>
            </span>
            <div class="flex items-center gap-x-2">
              <span
                v-if="!vmData[0].installed"
                class="hypervisor-button"
                @click="selectHypervisor('Bare Metal')"
                :class="{ 'hypervisor-active': vmData[0].hypervisor === 'Bare Metal' }"
                >Bare Metal</span
              >
              <span
                v-if="!vmData[0].installed"
                class="hypervisor-button"
                @click="selectHypervisor('Hosted')"
                :class="{ 'hypervisor-active': vmData[0].hypervisor === 'Hosted' }"
                >Hosted</span
              >

              <span
                v-if="vmData[0].installed"
                class="hypervisor-button disabled"
                :class="{ 'hypervisor-active': vmData[0].hypervisor === 'Bare Metal' }"
                >Bare Metal</span
              >
              <span
                v-if="vmData[0].installed"
                class="hypervisor-button disabled"
                :class="{ 'hypervisor-active': vmData[0].hypervisor === 'Hosted' }"
                >Hosted</span
              >
            </div>
          </div>

          <div class="py-4">
            <span class="flex items-center gap-x-2 mb-2 text-dark">
              <font-awesome-icon icon="fa-solid fa-computer" class="h-5 w-5" />
              <h4>Operating System</h4>
            </span>
            <v-select
              v-model="vmData[0].os"
              :options="osOptions"
              class="w-full mt-2"
              placeholder="Select Operating System"
              :disabled="vmData[0].installed"
            />
          </div>

          <div class="py-4">
            <span class="flex items-center gap-x-2 mb-2 text-dark">
              <font-awesome-icon icon="fa-solid fa-lock-open" class="h-5 w-5" />
              <h4>Access Control Type</h4>
            </span>
            <v-select
              v-model="vmData[0].type"
              :options="accessControlOptions"
              class="w-full mt-2"
              placeholder="Select Access Control"
              :disabled="vmData[0].installed"
            />
          </div>

          <div class="py-4">
            <span class="flex items-center gap-x-2 mb-2 text-dark">
              <font-awesome-icon icon="fa-solid fa-globe" class="h-5 w-5" />
              <h4>IP Address</h4>
            </span>
            <v-select
              v-model="vmData[0].ip"
              :options="ipOptions"
              class="w-full mt-2"
              placeholder="Select IP Address"
              :disabled="vmData[0].installed"
            />
          </div>

          <div v-if="!bothInstalled" class="flex flex-col items-center gap-y-3 mt-4">
            <vs-button
              class="w-full font-bold"
              size="md"
              icon="icon-alert-triangle"
              icon-pack="feather"
              color="primary"
              @click="viewRequirements(0)"
              v-if="!vmData[0].installing && !vmData[0].installed"
              >View Requirements
            </vs-button>
            <vs-button
              class="w-full font-bold"
              size="md"
              icon="icon-download"
              icon-pack="feather"
              color="success"
              :disabled="vmData[0].hypervisor === null || vmData[0].os === null || vmData[0].type === null || vmData[0].ip === null"
              @click="installVirtualMachine(0)"
              v-if="!vmData[0].installing && !vmData[0].installed"
              >Install Virtual Machine
            </vs-button>
            <vs-button
              class="w-full font-bold"
              size="md"
              icon="icon-loader"
              icon-pack="feather"
              color="warning"
              disabled
              v-if="vmData[0].installing"
              >Installing..
            </vs-button>
            <vs-button
              class="w-full font-bold"
              size="md"
              icon="icon-arrow-right"
              icon-after
              icon-pack="feather"
              color="success"
              @click="currentVM = 2"
              v-if="vmData[0].installed"
              >Next Machine
            </vs-button>
          </div>

          <div v-if="bothInstalled" class="flex items-center gap-x-2 mt-4">
            <vs-button
              class="w-1/2 font-bold"
              size="md"
              icon="icon-arrow-left"
              icon-pack="feather"
              color="danger"
              :disabled="currentVM === 1"
              @click="currentVM = 1"
              >Previous
            </vs-button>
            <vs-button
              class="w-1/2 font-bold"
              size="md"
              icon="icon-arrow-right"
              icon-after
              icon-pack="feather"
              color="success"
              :disabled="currentVM === 2"
              @click="currentVM = 2"
              >Next
            </vs-button>
          </div>
        </vx-card>

        <vx-card class="mb-base" v-if="currentVM === 2">
          <div class="flex items-center justify-between w-full">
            <h3 class="font-bold mb-4">VM Configurator</h3>
            <h3 class="font-light mb-4">{{ currentVM }}/2</h3>
          </div>

          <div class="py-4">
            <span class="flex items-center gap-x-2 mb-2 text-dark">
              <font-awesome-icon icon="fa-solid fa-server" class="h-5 w-5" />
              <h4>Hypervisor</h4>
            </span>
            <div class="flex items-center gap-x-2">
              <span
                v-if="!vmData[1].installed"
                class="hypervisor-button"
                @click="selectHypervisor('Bare Metal')"
                :class="{ 'hypervisor-active': vmData[1].hypervisor === 'Bare Metal' }"
                >Bare Metal</span
              >
              <span
                v-if="!vmData[1].installed"
                class="hypervisor-button"
                @click="selectHypervisor('Hosted')"
                :class="{ 'hypervisor-active': vmData[1].hypervisor === 'Hosted' }"
                >Hosted</span
              >

              <span
                v-if="vmData[1].installed"
                class="hypervisor-button disabled"
                :class="{ 'hypervisor-active': vmData[1].hypervisor === 'Bare Metal' }"
                >Bare Metal</span
              >
              <span
                v-if="vmData[1].installed"
                class="hypervisor-button disabled"
                :class="{ 'hypervisor-active': vmData[1].hypervisor === 'Hosted' }"
                >Hosted</span
              >
            </div>
          </div>

          <div class="py-4">
            <span class="flex items-center gap-x-2 mb-2 text-dark">
              <font-awesome-icon icon="fa-solid fa-computer" class="h-5 w-5" />
              <h4>Operating System</h4>
            </span>
            <v-select
              v-model="vmData[1].os"
              :options="osOptions"
              class="w-full mt-2"
              placeholder="Select Operating System"
              :disabled="vmData[1].installed"
            />
          </div>

          <div class="py-4">
            <span class="flex items-center gap-x-2 mb-2 text-dark">
              <font-awesome-icon icon="fa-solid fa-lock-open" class="h-5 w-5" />
              <h4>Access Control Type</h4>
            </span>
            <v-select
              v-model="vmData[1].type"
              :options="accessControlOptions"
              class="w-full mt-2"
              placeholder="Select Access Control"
              :disabled="vmData[1].installed"
            />
          </div>

          <div class="py-4">
            <span class="flex items-center gap-x-2 mb-2 text-dark">
              <font-awesome-icon icon="fa-solid fa-globe" class="h-5 w-5" />
              <h4>IP Address</h4>
            </span>
            <v-select
              v-model="vmData[1].ip"
              :options="ipOptions"
              class="w-full mt-2"
              placeholder="Select IP Address"
              :disabled="vmData[1].installed"
            />
          </div>

          <div v-if="!bothInstalled" class="flex flex-col items-center gap-y-3 mt-4">
            <vs-button
              class="w-full font-bold"
              size="md"
              icon="icon-alert-triangle"
              icon-pack="feather"
              color="primary"
              @click="viewRequirements(1)"
              v-if="!vmData[1].installing && !vmData[1].installed"
              >View Requirements
            </vs-button>
            <vs-button
              class="w-full font-bold"
              size="md"
              icon="icon-download"
              icon-pack="feather"
              color="success"
              :disabled="vmData[1].hypervisor === null || vmData[1].os === null || vmData[1].type === null || vmData[1].ip === null"
              @click="installVirtualMachine(1)"
              v-if="!vmData[1].installing && !vmData[1].installed"
              >Install Virtual Machine
            </vs-button>
            <vs-button
              class="w-full font-bold"
              size="md"
              icon="icon-loader"
              icon-pack="feather"
              color="warning"
              disabled
              v-if="vmData[1].installing"
              >Installing..
            </vs-button>
          </div>

          <div v-if="bothInstalled" class="flex items-center gap-x-2 mt-4">
            <vs-button
              class="w-1/2 font-bold"
              size="md"
              icon="icon-arrow-left"
              icon-pack="feather"
              color="danger"
              :disabled="currentVM === 1"
              @click="currentVM = 1"
              >Previous
            </vs-button>
            <vs-button
              class="w-1/2 font-bold"
              size="md"
              icon="icon-arrow-right"
              icon-after
              icon-pack="feather"
              color="success"
              :disabled="currentVM === 2"
              @click="currentVM = 2"
              >Next
            </vs-button>
          </div>
        </vx-card>
      </div>
      <div class="vx-col flex gap-x-8 lg:w-9/12 md:w-9/12 sm:w-5/5">
        <div class="bg-dark main-border rounded-lg mb-base w-1/2 overflow-hidden">
          <div class="flex items-center justify-between p-5 main-bottom-border">
            <h3 class="font-bold">Virtual Machine #1</h3>
            <span class="not-installed-pill" v-if="!vmData[0].installed && !vmData[0].installing && !vmData[0].failed">Not Installed</span>
            <span class="installing-pill" v-if="vmData[0].installing && !vmData[0].failed">Installing: {{ vmData[0].progress }}%</span>
            <span class="installed-pill" v-if="vmData[0].installed && !vmData[0].failed">Installed</span>
            <span class="not-installed-pill" v-if="vmData[0].failed">Failed</span>
          </div>

          <div class="flex flex-col justify-between" style="height: 81%">
            <div class="grid grid-cols-2 p-5 gap-4">
              <div class="items-center gap-x-2 bg-light main-border rounded-lg p-3">
                <h5>Hypervisor:</h5>
                <p v-if="vmData[0].hypervisor === null">Unselected</p>
                <p v-else>{{ vmData[0].hypervisor }}</p>
              </div>
              <div class="items-center gap-x-2 bg-light main-border rounded-lg p-3">
                <h5>Operating System:</h5>
                <p v-if="vmData[0].os === null">Unselected</p>
                <p v-else>{{ vmData[0].os }}</p>
              </div>
              <div class="items-center gap-x-2 bg-light main-border rounded-lg p-3">
                <h5>Access Control:</h5>
                <p v-if="vmData[0].type === null">Unselected</p>
                <p v-else>{{ vmData[0].type }}</p>
              </div>
              <div class="items-center gap-x-2 bg-light main-border rounded-lg p-3">
                <h5>IP Address:</h5>
                <p v-if="vmData[0].ip === null">Unselected</p>
                <p v-else>{{ vmData[0].ip }}</p>
              </div>
            </div>

            <div class="flex justify-center items-center w-full p-5">
              <radial-progress-bar
                :diameter="225"
                :completed-steps="vmData[0].progress"
                :total-steps="100"
                :animateSpeed="1100"
                timingFunc="ease"
                innerStrokeColor="#181d2a"
                startColor="#31B952"
                stopColor="#0cdc62"
                :strokeWidth="14"
                :innerStrokeWidth="14"
              >
                <img :style="{ display: vmData[0].hypervisor === 'Hosted' ? 'block' : 'none' }" :src="hostedImage" width="111px" />
                <img :style="{ display: vmData[0].hypervisor === 'Bare Metal' ? 'block' : 'none' }" :src="bareMetalImage" width="100px" />
                <img :style="{ display: vmData[0].hypervisor === null ? 'block' : 'none' }" :src="bareMetalImage" width="100px" />
              </radial-progress-bar>
            </div>
          </div>
        </div>

        <div class="bg-dark main-border rounded-lg mb-base w-1/2 overflow-hidden">
          <div class="flex items-center justify-between p-5 main-bottom-border">
            <h3 class="font-bold">Virtual Machine #2</h3>
            <span class="not-installed-pill" v-if="!vmData[1].installed && !vmData[1].installing && !vmData[1].failed">Not Installed</span>
            <span class="installing-pill" v-if="vmData[1].installing && !vmData[1].failed">Installing: {{ vmData[1].progress }}%</span>
            <span class="installed-pill" v-if="vmData[1].installed && !vmData[1].failed">Installed</span>
            <span class="not-installed-pill" v-if="vmData[1].failed">Failed</span>
          </div>

          <div class="flex flex-col justify-between" style="height: 81%">
            <div class="grid grid-cols-2 p-5 gap-4">
              <div class="items-center gap-x-2 bg-light main-border rounded-lg p-3">
                <h5>Hypervisor:</h5>
                <p v-if="vmData[1].hypervisor === null">Unselected</p>
                <p v-else>{{ vmData[1].hypervisor }}</p>
              </div>
              <div class="items-center gap-x-2 bg-light main-border rounded-lg p-3">
                <h5>Operating System:</h5>
                <p v-if="vmData[1].os === null">Unselected</p>
                <p v-else>{{ vmData[1].os }}</p>
              </div>
              <div class="items-center gap-x-2 bg-light main-border rounded-lg p-3">
                <h5>Access Control:</h5>
                <p v-if="vmData[1].type === null">Unselected</p>
                <p v-else>{{ vmData[1].type }}</p>
              </div>
              <div class="items-center gap-x-2 bg-light main-border rounded-lg p-3">
                <h5>IP Address:</h5>
                <p v-if="vmData[1].ip === null">Unselected</p>
                <p v-else>{{ vmData[1].ip }}</p>
              </div>
            </div>

            <div class="flex justify-center items-center w-full p-5">
              <radial-progress-bar
                :diameter="225"
                :completed-steps="vmData[1].progress"
                :total-steps="100"
                :animateSpeed="1100"
                timingFunc="ease"
                innerStrokeColor="#181d2a"
                startColor="#31B952"
                stopColor="#0cdc62"
                :strokeWidth="14"
                :innerStrokeWidth="14"
              >
                <img :style="{ display: vmData[1].hypervisor === 'Hosted' ? 'block' : 'none' }" :src="hostedImage" width="111px" />
                <img :style="{ display: vmData[1].hypervisor === 'Bare Metal' ? 'block' : 'none' }" :src="bareMetalImage" width="100px" />
                <img :style="{ display: vmData[1].hypervisor === null ? 'block' : 'none' }" :src="bareMetalImage" width="100px" />
              </radial-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vs-popup :active.sync="requirementsPopup1" class="pb-3" title="Virtual Machine #1 - Requirements">
      <div class="p-2 pt-2">
        <p class="text-dark text-lg">
          {{ activeUserInfo.firstName }}, Don has requested that we purchase him a new workstation so that he can perform his job on a
          Windows computer. The company does not want to spend more money on hardware, so they have directed that we provide Don with a
          virtual machine option on one of the employee workstations. We need to install a host-based hypervisor on 10.10.10.23 to help Don
          with his request. The VM needs to be configured so that access is based on the clearance levels of each employee. Additionally, we
          need to sign it an IP address between 10.10.10.20 and 10.10.10.30. I do not know which is available, so look to see what IP
          address options are there, and configure accordingly. If you have any questions, feel free to let me know. -John (IT Manager)
        </p>
      </div>
    </vs-popup>

    <vs-popup :active.sync="requirementsPopup2" class="pb-3" title="Virtual Machine #2 - Requirements">
      <div class="p-2 pt-2">
        <p class="text-dark text-lg">
          {{ activeUserInfo.firstName }}, we are also going to install a second VM onto 10.10.10.23. This VM needs to have a bare-metal
          approach which will provide a more secure VM with direct access to physical hardware resources. Will has asked that we create an
          isolated local cloud environment that will allow him to develop and test applications in a set up that mirrors the production
          cloud infrastructure. The VM needs to be configured so that only our cloud engineers can access it. For this, we want to stick
          with Linux, and if anything else is requested we can adjust later. Make the IP address the highest address option available. Let
          me know what it is so that I can pass it back to Will. As always {{ activeUserInfo.firstName }}, thanks for your hard work, and I
          know I can count on you to set this up. Ask me if you have questions. -John (IT Manager)
        </p>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import RadialProgressBar from 'vue-radial-progress';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      currentVM: 1,
      bothInstalled: false,
      vmInstallStatus: ['false', 'false'],

      requirementsPopup1: false,
      requirementsPopup2: false,

      vmData: [
        {
          hypervisor: null,
          os: null,
          type: null,
          ip: null,
          installed: false,
          installing: false,
          failed: false,
          progress: 0,
        },
        {
          hypervisor: null,
          os: null,
          type: null,
          ip: null,
          installed: false,
          installing: false,
          failed: false,
          progress: 0,
        },
      ],

      osOptions: ['Windows', 'Linux', 'macOS'],
      accessControlOptions: ['RBAC', 'ABAC', 'DAC', 'MAC', 'RuBAC'],
      ipOptions: ['10.10.10.27', '10.10.10.54', '10.10.10.33', '10.10.10.41'],

      bareMetalImage: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/175492847615830912/bare-metal.png',
      hostedImage: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/175492847615830912/cloud-hosted.png',
    };
  },
  methods: {
    selectHypervisor(selection) {
      if (this.currentVM === 1) {
        this.vmData[0].hypervisor = selection;
      } else {
        this.vmData[1].hypervisor = selection;
      }
    },

    installVirtualMachine(vm) {
      this.vmData[vm].failed = false;
      this.vmData[vm].installing = true;

      const intervalId = setInterval(() => {
        if (!this.vmData[vm].failed) {
          const randomProgress = Math.floor(Math.random() * (15 - 5 + 1)) + 5;

          this.vmData[vm].progress += randomProgress;
          this.vmData[vm].progress = Math.min(this.vmData[vm].progress, 100);

          if (this.vmData[vm].progress > 15) {
            if (vm === 0) {
              if (
                this.vmData[vm].hypervisor !== 'Hosted' ||
                this.vmData[vm].os !== 'Windows' ||
                this.vmData[vm].type !== 'MAC' ||
                this.vmData[vm].ip !== '10.10.10.27'
              ) {
                clearInterval(intervalId);
                this.installationFailed(vm);
              }
            } else if (
              this.vmData[vm].hypervisor !== 'Bare Metal' ||
              this.vmData[vm].os !== 'Linux' ||
              this.vmData[vm].type !== 'RBAC' ||
              this.vmData[vm].ip !== '10.10.10.54'
            ) {
              clearInterval(intervalId);
              this.installationFailed(vm);
            }
          }

          if (this.vmData[vm].progress >= 100) {
            this.vmData[vm].installing = false;
            this.vmData[vm].installed = true;
            if (vm === 1) {
              this.markSimulation();
            }
            clearInterval(intervalId);
          }
        }
      }, 750);
    },

    installationFailed(vm) {
      const title = 'Virtual Machine Configuration Error';
      const header = 'Invalid Configuration';
      const message = 'Your configuration does not seem to be correct! Please try again.';

      this.$emit('simulation_show_popup', title, header, message);
      this.vmData[vm].installing = false;
      this.vmData[vm].failed = true;
      this.vmData[vm].progress = 0;
    },

    viewRequirements(vm) {
      if (vm === 0) {
        this.requirementsPopup1 = true;
      } else {
        this.requirementsPopup2 = true;
      }
    },

    markSimulation() {
      let totalScore = 0;

      if (this.vmData[0].installed === true && this.vmData[1].installed === false) {
        totalScore = 1;

        this.$emit('simulation_getting_there', totalScore);
      } else if (this.vmData[0].installed === false && this.vmData[1].installed === true) {
        totalScore = 1;

        this.$emit('simulation_getting_there', totalScore);
      } else if (this.vmData[0].installed === true && this.vmData[1].installed === true) {
        totalScore = 2;

        return this.$emit('simulation_full_correct', totalScore);
      } else {
        this.$emit('simulation_getting_there', totalScore);
      }
    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  created() {
    this.osOptions = shuffle(this.osOptions);
    this.accessControlOptions = shuffle(this.accessControlOptions);
    this.ipOptions = shuffle(this.ipOptions);
  },
  components: {
    draggable,
    Prism,
    vSelect,
    shuffle,
    RadialProgressBar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.hypervisor-button {
  padding: 0.4rem 0.8rem;
  background-color: #040815;
  border: 1px solid #181d2a;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 50%;
  text-align: center;

  &:hover {
    border: 1px solid #334561;
  }
}

.hypervisor-active {
  background-color: #030f29 !important;
  border: 1px solid #0053ff !important;
}

.not-installed-pill {
  border-radius: 4rem;
  background-color: rgb(201, 55, 56, 0.3);
  border: 1px solid #c93738;
  padding: 0.3rem 1rem;
}

.installed-pill {
  border-radius: 4rem;
  background-color: rgb(49, 185, 82, 0.3);
  border: 1px solid #31b952;
  padding: 0.3rem 1rem;
}

.installing-pill {
  border-radius: 4rem;
  background-color: rgb(255, 159, 67, 0.3);
  border: 1px solid #ff9f43;
  padding: 0.3rem 1rem;
}

.disabled {
  cursor: not-allowed !important;
}
</style>
